<template>
  <div v-if="selectedPropertyOwnershipTypeObject">
    <div class="form--group">
      <div class="left--col">
        <label for="ownership-type-status-id-field">
          <span>{{ $t('addListing.propertyStatus') }}</span>
          <span class="required">*</span>
        </label>
      </div>
      <div class="right--col">
        <multiselect
          name="property_ownership_type_id"
          v-model="selectedPropertyOwnershipTypeObject"
          :options="propertyOwnershipTypeList"
          :allowEmpty="false"
          :close-on-select="true"
          :placeholder="$t('addListing.placeholder.status')"
          track-by="id"
          label="name"
          :custom-label="selectLabel"
          id="ownership-type-status-id-field"
        >
        </multiselect>
      </div>
    </div>
    <div
      class="form--group"
      v-if="selectedPropertyOwnershipTypeObject.requireRelatedName"
      :class="{ 'has-error': validation.hasError('propertyOwnershipTypeOwnerName') }"
    >
      <div class="left--col">
        <label :for="`owner-name-${selectedPropertyOwnershipType}-field`">
          <span>{{ selectedPropertyOwnershipTypeObject.nameTitle }}</span>
        </label>
      </div>
      <div class="right--col">
        <input
          :id="`owner-name-${selectedPropertyOwnershipType}-field`"
          :value="propertyOwnershipTypeOwnerName"
          @input="propOwnerChange"
          :name="'property_owner_name[' + selectedPropertyOwnershipType + ']'"
          class="basic--input"
          ref="propNameField"
          :placeholder="propertyOwnershipTypeOwnerPlaceholder"
        />
        <span class="val-error" v-if="validation.hasError('propertyOwnershipTypeOwnerName')">{{
          validation.firstError('propertyOwnershipTypeOwnerName')
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import { mapGetters, mapState } from 'vuex';
import { Validator } from 'simple-vue-validator';

require('vue-multiselect/dist/vue-multiselect.min.css');

export default {
  name: 'property-ownership-section',
  components: { Multiselect },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      propertyOwnershipTypeList: state =>
        state.v2.listingForm.sect1.location.propertyOwnershipTypeList,
      isConditionPrimaryValue: state => state.v2.listingForm.sect1.basicInfo.isConditionPrimary,
    }),
    ...mapGetters({
      selectedPropertyOwnershipType: 'v2/listingForm/sect1/location/selectedPropertyOwnershipType',
    }),
    propertyOwnershipTypeOwnerName() {
      if (
        this.selectedPropertyOwnershipTypeObject &&
        this.selectedPropertyOwnershipTypeObject.requireRelatedName
      ) {
        return this.selectedPropertyOwnershipTypeObject.ownerName;
      }
      return null;
    },
    propertyOwnershipTypeOwnerPlaceholder() {
      if (this.selectedPropertyOwnershipTypeObject) {
        return this.$i18n.t(
          'addListing.ownership.' + this.selectedPropertyOwnershipTypeObject.name + '.placeholder',
        );
      } else {
        return '';
      }
    },
    selectedPropertyOwnershipTypeObject: {
      get() {
        return this.$store.getters[
          'v2/listingForm/sect1/location/selectedPropertyOwnershipTypeObject'
        ];
      },
      set(value) {
        return this.$store.commit(
          'v2/listingForm/sect1/location/SET_SELECTED_PROPERTY_OWNERSHIP_TYPE_OBJECT',
          value,
        );
      },
    },
  },
  validators: {
    propertyOwnershipTypeOwnerName: {
      validator(value) {
        if (this.isConditionPrimaryValue) return;
        if (
          this.selectedPropertyOwnershipTypeObject &&
          this.selectedPropertyOwnershipTypeObject.requireRelatedName
        ) {
          return Validator.value(value).maxLength(
            100,
            this.$i18n.t('errors.addListing.owner.max', { max: 100 }),
          );
        }
      },
    },
  },
  methods: {
    validate() {
      return this.$validate().then(
        function(success) {
          return !!success;
        }.bind(this),
      );
    },
    propOwnerChange($elem) {
      const payload = {
        propertyOwnerTypeId: this.selectedPropertyOwnershipType,
        propertyOwnerTypeName: $elem.target.value,
      };
      this.$store.commit('v2/listingForm/sect1/location/CHANGE_PROP_OWNERSHIP_NAME', payload);
    },
    selectLabel({ name }) {
      if (!name) {
        return '';
      }
      return this.$i18n.t('addListing.ownership.' + name + '.title');
    },
  },
};
</script>

<style scoped></style>
